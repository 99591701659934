import './App.css';
import {Fab, IconButton, Snackbar, SnackbarCloseReason, Tooltip} from "@mui/material";
import {Close, Logout} from "@mui/icons-material";
import {Fragment, SyntheticEvent, useEffect, useState} from "react";
import HelpFrame from "./HelpFrame";
import AuthWrapper from "./authWrapper";
import {onAuthStateChanged, User} from "firebase/auth";
import {auth} from "./firebase";
import {monitorAuthState, signOut} from "./authUtils";

/**
 * The main application component.
 */
export default function App() {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarText, setSnackbarText] = useState('');
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);

  /**
   * Effect hook to monitor authentication state changes.
   */
  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        console.log("User signed in: ", user);
        setSnackbarText(`Welcome, ${user.displayName}`);
        setSnackbarOpen(true);
        setUser(user);
        monitorAuthState(user);
      } else {
        console.log("no user signed in", user);
        setUser(null);
      }
    });
  }, []);

  /**
   * Handles the click event for the floating action button (FAB).
   *
   * @returns {Promise<void>} A promise that resolves when the sign-out process is complete.
   */
  async function fabClick(): Promise<void> {
    try {
      await signOut();
    } catch (error: any) {
      setSnackbarText(`Error signing out. ${error.message}`);
      setSnackbarOpen(true);
    }
  }

  /**
   * Handles the close event for the Snackbar.
   *
   * @param {SyntheticEvent | Event} event - The event object.
   * @param {SnackbarCloseReason} [reason] - The reason for closing the Snackbar.
   */
  function handleSnackbarClose(
    event: SyntheticEvent | Event,
    reason?: SnackbarCloseReason,
  ) {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarOpen(false);
  }

  // action for the Snackbar
  const action = (
    <Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleSnackbarClose}
      >
        <Close fontSize="small"/>
      </IconButton>
    </Fragment>
  );

  return (
    <div className="App">
      <AuthWrapper user={user} setUser={setUser} loading={loading} setLoading={setLoading}>
        <HelpFrame/>
        <div className="fab">
          <Tooltip title="sign out">
            <Fab color="primary" aria-label="share" onClick={fabClick} size="small">
              <Logout fontSize="small"/>
            </Fab>
          </Tooltip>
        </div>
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={3000}
          onClose={handleSnackbarClose}
          message={snackbarText}
          action={action}
        />
      </AuthWrapper>
    </div>
  );
}