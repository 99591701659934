import './App.css';
import React, {useEffect} from 'react';
import {Box, Button, CircularProgress, Typography} from "@mui/material";
import {Login} from "@mui/icons-material";
import {signInWithCustomToken, User} from "firebase/auth";
import {auth} from "./firebase";
import {checkAuthStatus} from "./authUtils";


interface AuthWrapperProps {
  children: React.ReactNode;
  user: User | null;
  setUser: (user: User | null) => void;
  loading: boolean;
  setLoading: (loading: boolean) => void;
}

export default function AuthWrapper({children, user, setUser, loading, setLoading}: AuthWrapperProps) {
  useEffect(() => {
    async function verifyAuth() {

      try {
        const token = await checkAuthStatus();
        signInWithCustomToken(auth, token);
        setLoading(false);
      } catch (error) {
        console.log("error", error);
        setUser(null);
        setLoading(false);
      }
    }

    verifyAuth();
  }, []);

  if (loading && !user) {
    return <Box className="fill center">
      <CircularProgress/>
      <Typography variant='h5' sx={{m: 1.5}}>Loading...</Typography>
    </Box>;
  }

  if (!user) {
    const currentUrl = window.location.href;

    return <Box className="fill center flex-column">
      <img src="/pwi-steel-web.png" alt="PWI Logo" className="splash-image"/>
      <Typography variant="h5" sx={{mt: 3}}>Software Help</Typography>
      <Typography variant="body1">Please sign in to view.</Typography>
      <Button variant="contained" sx={{mt: 2, mb: 5}} startIcon={<Login/>}
              href={`https://auth.pwiworks.app/sign-in?redirect=${currentUrl}`}>Sign In</Button>
    </Box>;
  }

  return <>{children}</>;
}
