import './App.css';
import {useEffect} from "react";

interface IFrameUrlEvent extends MessageEvent {
  data: {
    currentUrl?: string;
  };
}


export default function HelpFrame() {
  const bucketUrl = 'https://storage.googleapis.com/pwi-apps-help-documentation';

  function getUrlFromHash() {
    //get hash before iframe is loaded so that we can redirect after load if necessary
    const initialHash = window.location.hash;
    // determine if someone wants a specific page, and send them to that page
    if (initialHash) {
      // Extract the filename and additional hash from the initial hash
      const [filename, ...additionalHashParts] = initialHash.slice(1).split('#');
      const additionalHash = additionalHashParts.join('#');
      // Set the iframe to the new URL
      return `${bucketUrl}/${filename}.html${additionalHash ? `#${additionalHash}` : ''}`;
    }
    return `${bucketUrl}/index.html`;
  }

  const iframeUrl = getUrlFromHash();

  useEffect(() => {
    const iframe = document.getElementById('helpFrame') as HTMLIFrameElement;
    iframe.addEventListener('load', registerUrlWatcher);

    return () => {
      iframe.removeEventListener('load', registerUrlWatcher);
    };
  }, []);


  // Function to request the current URL from the iframe
  function registerUrlWatcher() {
    const iframe = document.getElementById('helpFrame') as HTMLIFrameElement
    // Send a message to the iframe
    iframe.contentWindow?.postMessage('registerUrlWatcher', '*');
  }

  // Add an event listener for messages from the iframe
  window.addEventListener('message', handleIframeResponse, false);

  // Function to handle the response from the iframe
  function handleIframeResponse(event: IFrameUrlEvent) {
    // Check if event data is an object and contains the key currentUrl
    if (typeof event.data === 'object' && 'currentUrl' in event.data) {
      // Log the received URL
      const newHash = extractHashFromUrl(event.data.currentUrl);
      if (newHash && window.location.hash !== newHash) {
        window.location.hash = newHash;
      }
    }
  }

  function extractHashFromUrl(url: string | undefined): string | undefined {
    if (!url) {
      return undefined;
    }
    const newUrl = new URL(url);
    const filename = newUrl.pathname.split('/').pop()?.split('.')[0];
    const hash = newUrl.hash;
    if (filename) {
      return `${filename}${hash}`;
    }
    return undefined;
  }



  return (
    <iframe
      id="helpFrame"
      src={iframeUrl}
      title="Help"
      className="fullscreen-iframe fill"
    ></iframe>
  );
}