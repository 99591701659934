import {auth} from "./firebase";
import {User} from "firebase/auth";

// usage examples can be found at ../auth-utils.md

/**
 * A boolean value indicating whether the current domain is a subdomain of pwiworks.app.
 * If true, the authentication status will be checked using the auth-status API endpoint.
 */
export const onAuthSubdomain = window.location.href.indexOf('pwiworks.app') >= 0;

/**
 * Checks the authentication status by making a GET request to the auth-status API endpoint.
 *
 * @returns {Promise<string>} A promise that resolves to the response text if the authentication is successful.
 * @throws {Error} Throws an error if the response status is not 200, indicating the user is not signed in.
 */
export async function checkAuthStatus(): Promise<string> {
  if (!onAuthSubdomain) {
    throw new Error('cross-domain authentication is not supported on this domain');
  }
  const apiUrl = 'https://auth.pwiworks.app/api/auth-status';

  const requestOptions: RequestInit = {
    method: 'GET',
    credentials: 'include' // Include credentials (cookies) in the request
  };

  console.log("checking auth");

  const response = await fetch(apiUrl, requestOptions);

  // If the response status is not 200, throw an error
  if (response.status !== 200) {
    console.log("not signed in");
    throw new Error('not-signed-in');
  }

  // Return the response text
  console.log("signed in");
  return response.text();
}

/**
 * Monitors the authentication state by periodically checking for the presence of a session cookie.
 * If the session cookie is not present and the user is not null, it signs out the user.
 *
 * @param {User | null} user - The current user object or null if no user is signed in.
 */
export function monitorAuthState(user: User | null): void {
  if (onAuthSubdomain) {
    const authCheckTimer = setInterval(async () => {
      if (!sessionCookieIsPresent() && user !== null) {
        console.log('Session cookie not present, signing out');
        clearInterval(authCheckTimer);
        await auth.signOut();
      }
    }, 2000);
  } else {
    //do nothing
  }
}

/**
 * Checks if the session cookie is present in the document's cookies.
 *
 * @returns {boolean} True if the session cookie is present, false otherwise.
 */
function sessionCookieIsPresent(): boolean {
  const cookies = document.cookie ?? "";
  const sessionCookie = cookies.split(';').find(cookie => cookie.trim().startsWith('__session='));
  return sessionCookie !== undefined && sessionCookie.trim() !== '';
}

/**
 * Signs out the user by making a POST request to the clear-session-cookie API endpoint.
 *
 * @returns {Promise<void>} A promise that resolves when the sign-out process is complete.
 * @throws {Error} Throws an error if the response status is not 200, indicating the sign-out process failed.
 */
export async function signOut(): Promise<void> {

  if (onAuthSubdomain) {
    const apiUrl = 'https://auth.pwiworks.app/api/clear-session-cookie';
    const requestOptions: RequestInit = {
      method: 'POST',
      credentials: 'include' // Include credentials (cookies) in the request
    };

    console.log("signing out");
    const response = await fetch(apiUrl, requestOptions);

    // If the response status is not 200, throw an error
    if (response.status !== 200) {
      console.log("error signing out", response.body);
      throw new Error('not-signed-in');
    }
  }

  // Sign out of Firebase locally to prompt state updates
  await auth.signOut();

  // Log the successful sign-out
  console.log("signed out");
}